@import "./src/assets/scss/main.scss";
































































































































































.orderRaw {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    //font-display: column;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    width: 100%;

    &__item {
        width: calc(50% - 40px);
    }

    &__item-header {
        text-align: center;
        background: #3F2A2C;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 55px;
        line-height: 100.74%;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
        text-transform: uppercase;

        color: #FFFFFF;

    }

    &__item-header--orange {
        background: #FF7728;
        margin-bottom: 50px;
    }
}

.orderItem25 {
    width: calc(25% - 12px);
    height: 90px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EAEAEA;
    border-radius: 9px;
    margin-right: 12px;
    margin-bottom: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 56px;
    line-height: 100.74%;
}

.orderItem33 {
    color: #FF7728;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 1;
    margin-bottom: 40px;
    /* or 201px */

    text-transform: uppercase;

    color: #FF7728;

}

.orderItem25Items {
    display: flex;
    flex-wrap: wrap;
}

.orderHeader {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    margin-top: 27px;
    align-items: center;
    margin-bottom: 45px;

    img {
        max-width: 200px;
        height: auto;
    }
}

.orderTime {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 90px;
    line-height: 100.74%;
    /* or 181px */

    text-transform: uppercase;

    color: #000000;
}
